
export const API = {
  latLng: '23.93716392875188,90.19898396729388',
  BASE_URL_KYC: 'http://36.255.71.86:9002',
//  BASE_API_URL: 'http://localhost:9001',
 PAYMENT_CLIENT_AUDITOR: 'https://enlistment.frc.gov.bd/auditor/fee-deposit-details',
 PAYMENT_CLIENT_FIRM: 'https://enlistment.frc.gov.bd/firm/payment',
 REDIRECT_DASHBOARD: 'https://enlistment.frc.gov.bd',
 BASE_API_URL: 'https://enlistmentapi.frc.gov.bd',
  // BASE_URL_BO: 'http://129.150.38.18:9001',
  BASE_WORKFLOW_URL:'https://enlistmentapi.frc.gov.bd',
  // BASE_WORKFLOW_URL:'http://localhost:9003',
  BASE_URL_file: 'https://enlistmentapi.frc.gov.bd/frc-prod/',



  SEND_EMAIL_OTP_USER: '/public/api/v1/user/send-email-verify-code',
  VERIFY_EMAIL_OTP_USER: '/public/api/v1/user/otp-verify-for-email',

  SEND_MOBILE_OTP_USER: '/public/api/v1/user/send-mobile-verify-code',
  VERIFY_MOBILE_OTP_USER: '/public/api/v1/user/otp-verify-for-mobile',
  SIGNUP_USER: '/public/api/v1/user/user-init-signup',
  AUDITOR_BASIC_INFO:'/api/v1/auditor/save',
  GET_AUDITOR_BASIC_INFO:'/api/v1/auditor/save',
  GET_AUDITOR_CRITERIA:'/api/v1/auditor/criteria',
  AUDITOR_CRITERIA_SAVE:'/api/v1/auditor/criteria',
  FIRM_CRITERIA_SAVE:'/api/v1/firm/criteria',
  SAVE_FILE:'/api/v1/s3/buckets/frc-appdev-bucket/file?publicObject=true',
  DELETE_FILE:'/api/v1/s3/buckets/frc-appdev-bucket/objects',
  AUDITOR_BASIC_INFO_PREVIEW:'/api/v1/auditor/preview',
  AUDITOR_BASIC_INFO_SUBMIT:'/api/v1/auditor/submit',

  GET_SETTINGS_USER: '/api/v1/user/settings',
  UPDATE_SETTINGS_USER: '/api/v1/user/settings/save',
  AUDITOR_PAYMENT:'/api/v1/auditor/payment',
  AUDITOR_ONLINE_PAYMENT:'/commerz/pay',
  GET_AUDITOR_PAYMENT_BY_ID:'/api/v1/auditor/payment/one/561',
  FIRM_ADDRESS:'/api/v1/firm-addresses',
  FIRM_BASIC_INFO:'/api/v1/firm',
  UPDATE_APPLICABLE_FOR_FIRM_BASIC_INFO:'/api/v1/firm/update/applicable',
  FIRM_BASIC_ONLY:'/api/v1/firm/only',
  FIRM_BRANCH_SAVE:'/api/v1/firm/branches',
  FIRM_BRANCH_PREVIEW:'/api/v1/firm/preview',
  FIRM_BRANCH_PREVIEW_SUBMIT:'/api/v1/firm/preview/submit',
  GET_FIRM_BRANCH:'/api/v1/firm/branches/all',
  GET_ONE_FIRM_BRANCH:'/api/v1/firm/branches',
  DELETE_ONE_FIRM_BRANCH:'/api/v1/firm/branches',
  GET_FIRM_ADDRESS_BY_FIRM_ID:'/api/v1/firm-addresses/all/',
  GET_FIRM_HEAD_OFFICE_ADDRESS:'/api/v1/firm-addresses/mainoffice',
  AUDITOR_INVITATION:'/api/v1/auditor/invite',
  GET_INVITED_AUDITORS:'/api/v1/auditor/invited',
  DELETE_INVITED_AUDITORS:'/api/v1/auditor/delete',
  UPDATE_INVITED_AUDITORS:'/api/v1/auditor/role/update',
  AUDITOR_INVITATION_CONFIRMATION:'/api/v1/auditor/invite/confirmation',
  FIRM_ONGOING_ENGAGEMENT_FEE:'/api/v1/firm-agreement-fees',
  GET_FIRM_ONGOING_ENGAGEMENT_FEE:'/api/v1/firm-agreement-fees/all/',
  DELETE_FIRM_ONGOING_ENGAMENT_FEE:'/api/v1/firm-agreement-fees/',
  CREATE_FIRM_IT_PROFETIONAL:'/api/v1/firmitprofessional',
  DELETE_FIRM_IT_PROFETIONAL:'/api/v1/firmitprofessional/',
  GET_ALL_FIRM_IT_PROFETIONAL:'/api/v1/firmitprofessional/all/',
  GET_ONE_FIRM_IT_PROFETIONAL:'/api/v1/firmitprofessional/',

  CREATE_FIRM_IT_SYSTEM:'/api/v1/firmITExDetails',
  DELETE_FIRM_IT_SYSTEM:'/api/v1/firmITExDetails/',
  GET_ALL_FIRM_IT_SYSTEM:'/api/v1/firmITExDetails/all/',
  GET_ONE_FIRM_IT_SYSTEM:'/api/v1/firmITExDetails/',

  CREATE_FIRM_DEPOSITE_FEE:'/api/v1/firm-fees',
  DELETE_FIRM_DEPOSITE_FEE:'/api/v1/firm-fees/',
  GET_ALL_FIRM_DEPOSITE_FEE:'/api/v1/firm-fees/all/',
  GET_ONE_FIRM_DEPOSITE_FEE:'/api/v1/firm-fees/',

  CREATE_FIRM_PART_A:'/api/v1/firm-audit-part-a',
  DELETE_FIRM_PART_A:'/api/v1/firm-audit-part-a/',
  GET_ALL_FIRM_PART_A:'/api/v1/firm-audit-part-a/all/',
  GET_ONE_FIRM_PART_A:'/api/v1/firm-audit-part-a/',

  CREATE_FIRM_PART_B_ATTACHMENT:'/api/v1/firm-audit-part-b',
  DELETE_FIRM_PART_B_ATTACHMENT:'/api/v1/firm-audit-part-b/',
  GET_ALL_FIRM_PART_B_ATTACHMENT:'/api/v1/firm-audit-part-b/all/',
  GET_ONE_FIRM_PART_B_ATTACHMENT:'/api/v1/firm-audit-part-b/',


  CREATE_FIRM_PART_B_DETAILS:'/api/v1/firm-audit-part-b-details',
  DELETE_FIRM_PART_B_DETAILS:'/api/v1/firm-audit-part-b-details/',
  GET_ALL_FIRM_PART_B_DETAILS:'/api/v1/firm-audit-part-b-details/all/',
  GET_ONE_FIRM_PART_B_DETAILS:'/api/v1/firm-audit-part-b-details/',

  CREATE_FIRM_PART_B_C_DETAILS:'/api/v1/firm-audit-part-b-c-details',
  DELETE_FIRM_PART_B_C_DETAILS:'/api/v1/firm-audit-part-b-c-details/',
  GET_ALL_FIRM_PART_B_C_DETAILS:'/api/v1/firm-audit-part-b-c-details/all/',
  GET_ONE_FIRM_PART_B_C_DETAILS:'/api/v1/firm-audit-part-b-c-details/',


  CREATE_FIRM_PART_C_DETAILS:'/api/v1/firm-audit-part-c-details',
  DELETE_FIRM_PART_C_DETAILS:'/api/v1/firm-audit-part-c-details/',
  GET_ALL_FIRM_PART_C_DETAILS:'/api/v1/firm-audit-part-c-details/all/',
  GET_ONE_FIRM_PART_C_DETAILS:'/api/v1/firm-audit-part-c-details/',

  CREATE_FIRM_PART_D:'/api/v1/firm-audit-part-d',
  DELETE_FIRM_PART_D:'/api/v1/firm-audit-part-d/',
  GET_ALL_FIRM_PART_D:'/api/v1/firm-audit-part-d/all/',
  GET_ONE_FIRM_PART_D:'/api/v1/firm-audit-part-d/',

  CREATE_FIRM_PART_D_DETAILS:'/api/v1/firm-audit-part-d-details',
  DELETE_FIRM_PART_D_DETAILS:'/api/v1/firm-audit-part-d-details/',
  GET_ALL_FIRM_PART_D_DETAILS:'/api/v1/firm-audit-part-d-details/all/',
  GET_ONE_FIRM_PART_D_DETAILS:'/api/v1/firm-audit-part-d-details/',

  CREATE_FIRM_BASIC_ATTACHMENT:'/api/v1/firm-basic-attachments',
  GET_ALL_FIRM_BASIC_ATTACHMENT:'/api/v1/firm-basic-attachments/all/',

  FRC_BACK_OFFICE_AUDITOR_VIEW2:'pageNumber=20&pageSize=1',
  FRC_BACK_OFFICE_AUDITOR_VIEW:'/api/v1/auditor/admin/view/all',
  FRC_BACK_OFFICE_AUDITOR_EDIT:'/api/v1/auditor/admin/view/one',
  FRC_BACK_OFFICE_AUDITOR_VIEW1:'/api/v1/auditor/admin/view/all?status=DRAFT&searchText=',

  // ------------admin----------
  FRC_ADMIN_SEARCH_FIRM:'/api/v1/firm/preview/admin/view/all',
  FRC_ADMIN_EDIT_FIRM:'/api/v1/firm/preview/admin/view/one',
  WORKFLOW_FRC_GET_TAST_LIST:'/bpms/v1/wftasks/by-username',
  WF_DOER_BY_USERNAME:'/bpms/v1/wfdoers/username',
  WORKFLOW_FRC_ENLISTMENT_APPROVAL_SUBMIT:'/bpms/v1/wftasks/doer/submits',
  AUDITOR_PUBLISHED:'/api/v1/auditor/publish/list',
  FIRM_PUBLISHED:'/api/v1/firm/publish/list',


  GET_FIRM_PUBLISHED_LIST:'/public/api/v1/enlisted-firms?type=',
  GET_AUDITOR_PUBLISHED_LIST:'/public/api/v1/enlisted-auditors?type=',
  GET_FIRM_AND_AUDITOR_BY_TEXT:'/public/api/v1/enlisted-auditors/search',


  // payment
  GET_PAYMENT_ALL_DATA_WIT_PAGINATION:'/api/v1/orders',
  UPDATE_PAYMENT_STATUS:'/api/v1/orders',






  // need to delete
  FIND_ALL_USERS: '/api/v1/find-all-user',
  UPLOAD_FILES: '/api/v1/s3/buckets/frc-prod/file?publicObject=true',
  UPLOAD_FILE_DELETE: '/api/v1/s3/buckets/frc-prod/objects',
  get_FILES: '/api/v1/s3/buckets/frc-prod/objects',
  FIND_USER_BY_TOKEN: '/api/v1/get-user-by-token',
  FIND_USER_ME: '/public/api/v1/user/me',
  DASHBOARD_SUMMARY: '/api/v1/dashboard/summary',
  GET_AUDITOR_APPROVAL_STATUS:'/bpms/v1/wfsteps/by/ENLIST_AUDITOR/',
  GET_FIRM_APPROVAL_STATUS:'/bpms/v1/wfsteps/by/ENLIST_FIRM/',
  GET_CERTIFICATE:'/api/v1/cert/pdf?type=',
  GET_IS_VALID_FOR_DOWNLOAD_CERTIFICATE:'/api/v1/cert/valid_for_download?type=',
  GET_IS_VALID_FOR_FIRM_CERT:'/cert/firm-in-charge?type='

};


export enum ToasterPosition {
  topRight = 'toast-top-right',
  topLeft = 'toast-top-left',
  bottomRight = 'toast-bottom-right',
  bottomCenter = 'toast-bottom-center',
  bottomLeft = 'toast-bottom-left',
  // Other positions you would like
}

export enum Config {
  forcLogoUrl='',
 frcLogo='/assets/img/frc.png',
  // frcLogo='',
  bg='/assets/img/bg-login.jpeg',

  // Other positions you would like
}
